.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-modal-content {
  min-height: fit-content;
  width: 1000px;
/*overflow-y: auto;*/
/*overflow-x: auto;*/
}

.ant-modal {
  width: 1000px!important;
}

@font-face {
  font-family: 'Gothic';
  src: local('Gothic'), url(./assets/font/Century\ Gothic.ttf) format("truetype");
}